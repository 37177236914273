import { template as template_c4a637075c634f58a24833f45de8a794 } from "@ember/template-compiler";
const FKLabel = template_c4a637075c634f58a24833f45de8a794(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
