import { template as template_5f8db0aa345446b0b578c58cd7f6f5d0 } from "@ember/template-compiler";
const WelcomeHeader = template_5f8db0aa345446b0b578c58cd7f6f5d0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
