import { template as template_f7bf66e5e58442b79ce5e1ed5b63c006 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f7bf66e5e58442b79ce5e1ed5b63c006(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
